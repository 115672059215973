import { Routes } from '../../../enums/routes';

import { FC } from 'react';
import { DashboardIcon } from '../../../components/Icon/Icons/Dashboard.icon';
import { getUser_getUser } from '../../../../apollo/queries/__generated__/getUser';
import { t } from '@lingui/macro';
import { DealIcon } from '../../../components/Icon/Icons/Deal.icon';
import { OpenNew } from '../../../components/Icon/Icons/OpenNew.icon';

type Links = {
  href: string;
  label: string;
  hide?: boolean;
  linkIcon: FC;
  external?: boolean;
}[];

type NavigationStructure = {
  header: string;
  links: Links;
}[];

type Props = {
  user: getUser_getUser;
  isAdmin: boolean;
  // communityLink: CommunityLink;
};
type GetNavigationsStructure = (props: Props) => NavigationStructure;

export const getNavigationStructure: GetNavigationsStructure = ({
  user,
  isAdmin,
  // communityLink,
}) => {
  const showLinks = user?.profile && user?.onboardingData;

  if (!showLinks) {
    return [];
  }

  return [
    {
      header: t`menu`,
      links: [
        {
          href: Routes.Admin,
          label: 'Admin',
          linkIcon: DashboardIcon,
          hide: !isAdmin,
        },
        {
          href: Routes.Dashboard,
          label: 'Seznam kapitol',
          linkIcon: DashboardIcon,
        },
        {
          href: 'https://digital-class.startupbox.cz/podnikatele-a-odbornici',
          label: t`Odborníci`,
          linkIcon: DealIcon,
          external: true,
        },

        {
          href: 'https://www.facebook.com/groups/378113943455510',
          label: t`Komunita`,
          linkIcon: OpenNew,
          external: true,
        },
        // {
        //   href: Routes.SpecialOffers,
        //   label: t`Výhody a slevy`,
        //   linkIcon: SaleCouponsIcon,
        // },
      ],
    },
    // {
    //   header: t`můj účet`,
    //   links: [
    //     {
    //       href: Routes.UserProfileEdit,
    //       label: t`Můj profil`,
    //       linkIcon: SettingsIcon,
    //     },
    //
    //   ],
    // },
  ];
};
