import React, { FC } from 'react';

import styled from 'styled-components';

export const ProjectLogo: FC = () => {
  return <StyledImg src="/logo.svg" alt="logo" />;
};

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
