import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { colors } from '../../../styles/colors';

const BUTTON_SIZE = '45px';

const transformToCross: FlattenSimpleInterpolation = css`
  div:first-of-type {
    transform: rotate(45deg) translate(0px, -3px);
  }

  div:nth-of-type(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  div:last-of-type {
    transform: rotate(-45deg) translate(1px, 2px);
  }
`;

export const BurgerButton = styled.div`
  align-items: center;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  height: ${BUTTON_SIZE};
  justify-content: center;
  outline: 0;
  width: ${BUTTON_SIZE};

  /*
  TODO add breakpoints 
  */
  //@media (min-width: 480px) {
  //  display: none;
  //}
`;

export const BurgerBars = styled.div<{ isMenuOpen: boolean }>`
  position: relative;
  /*
  TODO unify z-indices 
  */
  z-index: 5;

  div {
    background-color: white;
    border-radius: 3px;
    height: 2px;
    margin-bottom: 5px;
    transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.33s ease;
    width: 24px;
  }

  div:first-of-type {
    transform-origin: 0 0;
  }

  div:last-of-type {
    margin-bottom: 0;
    transform-origin: 0 100%;
  }

  ${({ isMenuOpen }) => isMenuOpen && transformToCross}
`;

export const CircleBackground = styled.div<{ isMenuOpen: boolean }>`
  background: ${colors.blackMain};
  border-radius: 50%;
  height: ${BUTTON_SIZE};
  position: absolute;
  transform: ${({ isMenuOpen }) =>
    isMenuOpen && 'scale(75)  translate3d(1%, 1%, 0);'};
  transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  width: ${BUTTON_SIZE};
`;
