export enum Routes {
  Admin = '/admin',

  Auth = '/auth',
  AuthVerifyEmail = '/auth/verify-email',
  AuthRequestPasswordReset = '/auth/request-password-reset',

  Dashboard = '/',
  Detail = '/detail/[pageId]',
  Login = '/login',

  Onboarding = '/onboarding',

  UserProfileEdit = '/user-profile/edit',
  UserProfileCreation = '/user-profile/creation',
  Project = '/project',

  Registration = '/registration',
  Specialists = '/specialists',

  Success = '/success',

  Final = 'final',
  COURSE_COMPLETED = 'course-completed',
}
