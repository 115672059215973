import { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../styles/colors';
import React from 'react';
import { Navigation } from './Navigation';

type Props = {
  isMenuOpen: boolean;
  closeMenu(): void;
};

export const MobileMenu: FC<Props> = (props) => {
  const { isMenuOpen } = props;

  return (
    <MenuWrapper $isMenuOpen={isMenuOpen}>
      <Navigation />
    </MenuWrapper>
  );
};

const fadeIn = keyframes` 
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const MenuWrapper = styled.nav<{ $isMenuOpen: boolean }>`
  background: ${colors.blackMain};
  display: ${({ $isMenuOpen }) => !$isMenuOpen && 'none'};
  height: 100%;
  padding: 50px 20px;
  position: fixed;
  width: 100%;

  ${({ $isMenuOpen }) =>
    $isMenuOpen &&
    css`
      animation: ${fadeIn} 0.6s ease forwards;
    `};
`;
