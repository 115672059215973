import { FC, useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { AppBar, Box } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { colors } from '../../../styles/colors';
import { MobileMenuButton } from './MobileMenuButton';
import { MobileMenu } from './MobileMenu';
import { ProjectLogo } from './ProjectLogo';
import { Routes } from '../../../enums/routes';
import { useRouter } from 'next/router';

export const Navbar: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useRouter();

  const toggleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => setIsMenuOpen(false);

  const isButtonHidden = [
    Routes.Login,
    Routes.Registration,
    Routes.AuthRequestPasswordReset,
  ].includes(pathname as Routes);

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>
        <Link href="/">
          <a>
            <Box maxWidth={'100px'} maxHeight={'80%'} height={'100%'}>
              <ProjectLogo />
            </Box>
          </a>
        </Link>

        {!isButtonHidden && (
          <MobileMenuButton
            isMenuOpen={isMenuOpen}
            toggleIsMenuOpen={toggleIsMenuOpen}
          />
        )}
      </StyledToolbar>

      <MobileMenu isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
    </StyledAppBar>
  );
};

export const StyledAppBar = styled(AppBar)`
  height: 50px;
  background: ${colors.blackMain};

  z-index: 50;
`;

export const StyledToolbar = styled(Toolbar)`
  min-height: 100%;
  justify-content: space-between;
`;

/*
 height: ${size.inAppNavBarHeight};
 */
