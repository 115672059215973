import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment user on User {
    __typename
    id
    createdAt
    updatedAt
    firstName
    lastName
    email

    role
    emailVerification

    profile {
      id
      createdAt
      updatedAt
      region
      country
      gender
      age
    }

    onboardingResult
    onboardingData {
      answer
      question
    }

    quizes {
      type
    }
  }
`;
