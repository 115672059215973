import * as React from 'react';
import { SBXLogo } from './SBXLogo';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  message?: string;
  logo?: boolean;
};

export const LoadingScreen: FC<Props> = ({ message, logo }) => {
  return (
    <LoadingWrapper>
      {logo && <StyledLogo />}

      {message && <Typography>{message}</Typography>}

      <StyledCircularProgress color="primary" />
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  display: grid;
  grid-row-gap: 16px;
  place-items: center;
  place-content: center;
  height: 100vh;
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-top: 1rem;
`;

const StyledLogo = styled(SBXLogo)`
  height: 28px;
`;
