import React, { FC } from 'react';
import { Button, Typography, Link as MuiLink, Box } from '@mui/material';
import { Trans } from '@lingui/macro';
import Link from 'next/link';
import { Routes } from '../../../enums/routes';

type Props = {
  closeMenu?(): void;
};
export const NavigationRegisterPrompt: FC<Props> = ({ closeMenu }) => {
  const handleClick = () => (closeMenu ? closeMenu() : null);

  return (
    <>
      <Typography variant="h4" align="center" marginBottom={2}>
        <Trans>Ještě nemáte účet?</Trans>
      </Typography>
      <Typography align="center" marginBottom={3}>
        <Trans>
          Zaregistrujte se a získejte zdarma přístup k dalšímu obsahu
        </Trans>
      </Typography>

      <Link href={Routes.Registration}>
        <Button variant="contained" fullWidth onClick={handleClick}>
          <Trans>Registrovat se</Trans>
        </Button>
      </Link>

      <Box marginTop={2} style={{ color: 'white' }}>
        <MuiLink
          href="https://www.startupbox.cz/"
          target="_blank"
          color="inherit"
        >
          <Button fullWidth color="inherit">
            <Trans>Co je StartupBox?</Trans>
          </Button>
        </MuiLink>
      </Box>
    </>
  );
};
