import { isProduction } from './helpers';

type Type = {
  event: string;
  [key: string]: string | number;
};

export const analyticsDataLayerPush = ({ event, ...rest }: Type) => {
  console.log('isProduction', isProduction);

  if (isProduction) {
    window.dataLayer.push({ event, ...rest });
  }
};
